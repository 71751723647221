import { Flex, Tabs } from "antd";
import { useEffect, useState } from "react";
import Template from "./TemplateTabs/Template";
import { useSearchParams } from "react-router-dom";
import TagManagement from "./TemplateTabs/TagManagement";
import VariableManagement from "./TemplateTabs/VariableManagement";
import TemplateEdit from "./TemplateTabs/TemplateEdit";

var items = [
  {
    children: "",
    label: "New",
    key: "new",
    Render: (tab, setTab) => <Template tab={tab} setTab={setTab} />,
  },
  {
    children: "",
    label: "Edit",
    key: "edit",
    Render: (tab, setTab) => <TemplateEdit tab={tab} setTab={setTab} />,
  },
  { children: "", label: "Tags", key: "tag", Render: () => <TagManagement /> },
  {
    children: "",
    label: "Variables",
    key: "variable",
    Render: () => <VariableManagement />,
  },
];

export default function TemplateTabs() {
  const [tab, setTab] = useState();
  const [search, setSearch] = useSearchParams();
  useEffect(() => {
    if (!search.get("tab")) {
      search.set("tab", "new");
      setSearch(search);
      setTab("new");
    } else {
      setTab(search.get("tab"));
    }
  }, [search, setSearch]);
  function SetTag(key) {
    setTab(key);
    search.delete("id");
    search.set("tab", key);
    setSearch(search);
  }

  return (
    <Flex vertical>
      <Tabs
        onTabClick={(key) => SetTag(key)}
        activeKey={tab}
        type="card"
        size="large"
        items={items}
      />
      <div style={{ flex: 1, padding: "0 16px" }}>
        {items.find((k) => k.key === tab)?.Render(tab, setTab)}
      </div>
    </Flex>
  );
}

/**
 *
 * @param {string} hex
 * @returns
 */
const hextoRGB = (hex) => {
  if (hex.startsWith("rgb")) return hex;

  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  return `rgb(${r},${g},${b})`;
};

const getColorfromString = (str = "") => {
  if (str.includes("rgb(")) {
    let color = str
      .replace(/\s/g, "")
      .match(/rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/g)
      ?.toString();
    return color;
  }

  let color = str.match(/#[0-9a-fA-F]{6}/g);
  if (color) {
    color.forEach((c) => {
      str = str.replace(c, hextoRGB(c));
    });
  }
  return str;
};

const changetopx = (str = "") => {
  if (str.includes("px")) {
    return str;
  }
  if (str.includes("pt")) {
    return `${parseFloat(str) * 1.33}px`;
  }
};

function convertTagsToP(htmlString) {
  const openingTagRegex = /<(\/*)(h[1-6])(\s[^>]*)?>/gi;
  const newHtmlString = htmlString.replace(
    openingTagRegex,
    (match, p1, p2, p3) => {
      return `<${p1}p${p3 || ""}>`;
    }
  );

  return newHtmlString;
}

function formatPaste(htmlString) {
  var wrapper = document.createElement("div", { className: "wrapper" });
  wrapper.innerHTML = htmlString.replace(/<i/g, '<em ').replace(/<\/i/g, '</em ');
  var elements = wrapper?.querySelectorAll("*");
  elements.forEach(function (element) {
    var attributes = element.attributes;
    if (element.nodeName === "SPAN") {
      let defaultStyleYellow = element.style;
      if (defaultStyleYellow.backgroundColor === 'yellow' || defaultStyleYellow.background === 'yellow') {
        // Tạo thẻ <mark> và thay thế <span>
        const mark = document.createElement('mark');
        mark.innerHTML = element.innerHTML; // Sao chép nội dung từ <span> vào <mark>
        element.replaceWith(mark); // Thay thế <span> bằng <mark>
      }
    }
    if (element.nodeName === "TD") {
      let defaultStyle = element.style;
      let textstyle = "";
      if (defaultStyle.backgroundColor || defaultStyle.background) {
        textstyle += `background-color: ${getColorfromString(
          defaultStyle.backgroundColor || defaultStyle.background
        )};`;
      }

      if (defaultStyle.color) {
        textstyle += `color: ${getColorfromString(defaultStyle.color)};`;
      }

      if (defaultStyle.textalign) {
        textstyle += `text-align: ${defaultStyle.textalign};`;
      }
      if (defaultStyle.verticalAlign) {
        textstyle += `vertical-align: ${defaultStyle.verticalAlign};`;
      }

      if (defaultStyle.height) {
        textstyle += `height: ${changetopx(defaultStyle.height)};`;
      }
      if (defaultStyle.width) {
        textstyle += `width: ${defaultStyle.width};`;
      } else if (element.hasAttribute("width")) {
        textstyle += `width: ${element.width}px;`;
      }
      let p = element?.querySelectorAll("p");
      if (p.length === 1) {
        if (p[0].innerText === "") element.innerHTML = "";
        if (p[0].style.textAlign) {
          textstyle += `text-align: ${p[0].style.textAlign};`;
        }
        element.innerHTML = p[0].innerHTML;
      }

      element.style.cssText = textstyle;
    } else if (element.nodeName === "IMG") {
      element.removeAttribute("style");
      element.removeAttribute("data-line");
    } else {
      var textcss = "";
      if (element.style.textAlign) {
        textcss += `text-align: ${element.style.textAlign};`;
      }
      if (element.style.color) {
        textcss += `color: ${getColorfromString(element.style.color)};`;
      }
      if (element.style.fontSize) {
        textcss += `font-size: ${element.style.fontSize};`;
      }
      if (element.style.fontFamily) {
        textcss += `font-family: ${element.style.fontFamily.split(",")[0]};`;
      }

      if (element.nodeName === "COL") {
        if (element.style.width) {
          textcss += `width: ${element.style.width};`;
        } else if (element.hasAttribute("width")) {
          textcss += `width: ${element.width}px;`;
        }
        element.removeAttribute("width");
        console.log(element);
      }
      if (textcss === "") element.removeAttribute("style");
      else element.style.cssText = textcss;
    }

    for (var i = attributes.length - 1; i >= 0; i--) {
      if (!["style", "src", "width", "height", 'href'].includes(attributes[i].name)) {
        element.removeAttribute(attributes[i].name);
      }
    }
    if (element.nodeName === "TABLE") {
      element.border = 1;
      if (element.hasAttribute("width")) element.removeAttribute("width");
      element.style.width = "100%";
    }
  });

  return convertTagsToP(
    wrapper.innerHTML.replace(
      /<!--\[if !supportLists\]-->.*?<!--\[endif\]-->/gs,
      ""
    )
  );
}

/**
 * @type {import("../../public/static/tinymce/tinymce").BaseEditorOptions}
 */
const configTiny = {
  cache_suffix: `?v=1.6.0`,
  height: 500,
  menubar: false,
  plugins: "image table lists fullscreen pagebreak opmanual link",
  toolbar: [
    "undo redo",
    "fontfamily fontsize lineheight",
    "bold italic forecolor mark",
    "alignleft aligncenter alignright alignjustify",
    "bullist numlist checklist outdent indent fullscreen",
    "image imgup pagebreak table shape link unlink",
  ].join("|"),
  indentation: "48px",
  statusbar: false,
  font_size_formats:
    "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
  font_family_formats:
    "Times New Roman=times new roman,times,serif;Arial=arial,helvetica,sans-serif;Georgia=georgia,times new roman,times,serif;Tahoma=tahoma,arial,helvetica,sans-serif;Calibri=calibri,arial,helvetica,sans-serif;Helvetica=helvetica,arial,sans-serif;Courier New=courier new,courier,monospace",
  content_css: ["/static/tinymce.css", "/static/callout.css"],
  verify_html: false,
  paste_block_drop: true,
  valid_elements: "*[*]",
  extended_valid_elements: "*[*]",
  paste_auto_cleanup_on_paste: true,
  paste_postprocess: (plugin, args) => {
    const unwantedTags = ['v:shapetype', 'v:shape'];
    unwantedTags.forEach(tag => {
      const regex = new RegExp(`<${tag}[^>]*>(.*?)<\/${tag}>`, 'gi');
      args.node.innerHTML = args.node.innerHTML.replace(regex, '');
    });

    // Tìm các thẻ span có style background
    const spans = args.node.querySelectorAll('span[style*="background:yellow"]');
    spans.forEach(function (span) {
      // Tạo thẻ mark để thay thế span
      const mark = document.createElement('mark');
      mark.innerHTML = span.innerHTML;
      // Thay thế span bằng mark
      span.replaceWith(mark);
    });
  },
  paste_preprocess: (plugin, args) => {
    args.content = formatPaste(args.content);
    args.content = args.content.replace(/<span[^>]*>(\s|&nbsp;)*<\/span>/g, '');
  },
  toolbar_mode: "wrap",
  line_height_formats: '1 1.15 1.5 2',
  setup: function (editor) {
    editor.on('NodeChange', function (e) {
      editor.dom.select('table div').forEach(function (div) {
        editor.dom.remove(div, true);
      });

      let spans = editor.dom.select('span');

      spans.forEach(function (element) {
        let defaultStyleYellow = element.style;
        if (defaultStyleYellow.backgroundColor === 'yellow' || defaultStyleYellow.background === 'yellow') {
          const mark = document.createElement('mark');
          mark.innerHTML = element.innerHTML;
          editor.dom.replace(mark, element);
        }
      });

      e.content = editor.getContent();
    });
  },
  init_instance_callback: (editor) => {
    editor.contentDocument.addEventListener("mousedown", (e) => {
      let node = e.target;
      if (
        node.nodeName === "LI" &&
        node.parentNode?.className === "tox-checklist"
      ) {
        let x = e.target.getBoundingClientRect().x;
        if (e.clientX < x) {
          node.classList.toggle("tox-checklist--checked");
          e.preventDefault();
        }
      }
    });
  },
};

window.version = configTiny.cache_suffix;

export { configTiny };
